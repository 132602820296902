import Image from "next/image";
import { IoClose } from "react-icons/io5";
import { X } from "lucide-react"


interface Props {
    closeModal: () => void
    children: React.ReactNode
}


const AuthModal = ({ closeModal, children }: Props) => {
    return (
        <div className='absolute h-[110vh]  inset-0 flex center z-[1000] bg-gray-800/60 backdrop-blur-md px-2 '>
            <div className='w-full max-w-md sm:max-w-lg md:max-w-xl flex flex-col bg-white p-2  rounded-xl overflow-scroll scrollbar-hidden'>
                {closeModal &&
                <div className='w-full flex justify-end px-2 py-1'>

                <X onClick={closeModal} className=" rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground cursor-pointer"/>

                </div> }
                <div className=' flex flex-col items-center bg-white '>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default AuthModal;
