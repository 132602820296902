'use client'
import { useAuthModal } from '@/app/authModalProvider';
import { login } from '@/lib/endpoints';
import { useAppDispatch } from '@/lib/hooks';
import { setUser } from '@/redux/slices/userSlice';
import Image from 'next/image';
import { useState } from 'react';
import { Toaster, toast } from 'sonner';
import Input from '../shared/myInput';
import { clearLocationHistory } from '@/redux/slices/locationSlice';
import { Button } from '../shared/buttons/button';
import { Mail, Lock } from 'lucide-react'


const Login = () => {
    const { openRegisterModal, closeModal } = useAuthModal();

    const dispatch = useAppDispatch()
    const [form, setForm] = useState({
        email: '',
        // phoneNumber: '',
        password: ''
    });

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('email', form.email);
        formData.append('password', form.password);

        try {
            const response = await fetch(login, {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();

            if (data.error) {
                // Display toast for login failed with error message
                toast.error(`Login failed: ${data.error_msg}`);
                console.error('Login failed:', data.error_msg);
            } else if (data.user) {
                dispatch(clearLocationHistory())
                const { password, encrypted_password, salt, location, ...safeUserData } = data.user;
                // Dispatch the user data to the reducer where it will be persisted and stored in local storage
                dispatch(setUser(safeUserData));
                // Display toast for successful login
                toast.success('Login successful!');
                // Redirect to home page or close modal
                closeModal();
                //reload the window so that user location data can be fetched
                window.location.reload();
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An unexpected error occurred. Please try again.');
        }
    };


    return (
        <>
            <div className='w-full max-w-md mx-auto flex flex-col gap-2 text-foreground'>
                <Toaster richColors position="top-center" />

                <h3 className='text-foreground  text-center text-xl md:text-xl lg:text-xl font-bold'>Welcome Back!</h3>

                  <form onSubmit={handleSubmit} className="space-y-4">
                                <div className="space-y-2">
                                  <label htmlFor="email" className="text-sm font-medium text-gray-700">Email</label>
                                  <div className="relative">
                                    <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-faded" size={18} />
                                    <Input
                                      type="email"
                                      name="email"
                                      id="email"
                                      required
                                      placeholder="Enter your email"
                                      value={form.email}
                                      onChange={handleChange}
                                      className="pl-10 w-full"
                                    />
                                  </div>
                                </div>
                                <div className="space-y-2">
                                  <label htmlFor="password" className="text-sm font-medium text-gray-700">Password</label>
                                  <div className="relative">
                                    <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-faded" size={18} />
                                    <Input
                                      type="password"
                                      name="password"
                                      id="password"
                                      required
                                      placeholder="Enter your password"
                                      value={form.password}
                                      onChange={handleChange}
                                      className="pl-10 w-full"
                                    />
                                  </div>
                                </div>
                                <Button type="submit" className="w-full bg-primary text-white font-bold hover:bg-primary-dark transition-colors">
                                  Sign in
                                </Button>
                              </form>
                <p className='my-4 text-faded text-sm text-center'>Don&apos;t have an account yet? <Button className='p-0 font-semibold' variant={"link"} size={"sm"} onClick={() => openRegisterModal()}>Register for free</Button></p>

            </div >
        </>
    );
};

export default Login;
