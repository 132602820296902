'use client'
import { useEffect, useState } from "react"
import Modal from "./wrappers/modal"
import Image from "next/image"
import { useSettings } from "@/app/settingsProvider"
import { useAddAddressModal } from "@/app/addAddressModalProvider"
import { useAppDispatch, useAppSelector } from "@/lib/hooks"
import { addSavedAddress, setCurrentAddress, setNearestBranch } from "@/redux/slices/locationSlice"
import { getAllAddresses, getNearestBranch } from "@/lib/apiRequests"
import { toast, Toaster } from "sonner"
import { ChevronDown, MapPinned } from "lucide-react"
import { Button } from "./buttons/button"
import Lottie from "lottie-react"
import animationData from "@/components/animations/address_animation.json"
import { useAuthModal } from "@/app/authModalProvider"
import AuthModal from "./wrappers/authModal"
import Login from "../views/login"
import { Separator } from "./separator"
import LoaderSpinner from "@/components/shared/loader";



const InitialModal = ({hideModal}: {hideModal?: boolean}) => {
    const dispatch = useAppDispatch()
    const { user } = useAppSelector((state: any) => state.user)
    const currentAddress = useAppSelector((state: any) => state.location.currentAddress)
    const savedAddresses = useAppSelector((state: any) => state.location.savedAddresses)
    const { settings, settingsLoading, error } = useSettings();
    const { initialModal, setInitialModal, addAddressModal, setAddAddressModal } = useAddAddressModal();
  const { modalType, openLoginModal, closeModal: closeAuthModal } = useAuthModal();


    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };

    useEffect(() => {

        const fetchUserAddresses = async () => {
            if (user && user?.phone) {
                let formData = new FormData();
                formData.append('user_phone', user?.phone);
                const data = await getAllAddresses(formData);

                if (data.error) {
                    console.error('No addresses found for the user.');
                } else {
                    const addresses = data.records || [];
                    console.log("all addresses: ", addresses);

                    if (!Array.isArray(savedAddresses)) {
                        console.error("savedAddresses is not an array:", savedAddresses);
                    } else {
                        const savedAddressesSet = new Set(savedAddresses?.map((addr: any) => addr.id));
                        console.log("savedAddressesSet: ", savedAddressesSet);
                        addresses?.forEach((address: any) => {
                            if (!savedAddressesSet.has(address.id)) {
                                console.log("Adding address to saved addresses:", address);
                                dispatch(addSavedAddress({
                                    id: address.id,
                                    user_phone: address.user_phone,
                                    location: {
                                        latitude: parseFloat(address.location.split(',')[0]),
                                        longitude: parseFloat(address.location.split(',')[1]),
                                    },
                                    address: address.address,
                                    title: address.title,
                                    gpsAddress: address.gps_address,
                                    city: address.city,
                                }));
                                savedAddressesSet.add(address.id);
                            }
                        });

                    }
                }
            }

        };
        fetchUserAddresses();
    }, [user, dispatch, initialModal, savedAddresses]);

    const handleAddressSelect = async (selected: any) => {
        console.log('Selected address:', selected);
        if (!selected) {
            console.log('No address selected');
            return;
        }

        if (currentAddress && currentAddress.id === selected.id) {
            console.log('Selected address is already the current address');
            toast.error('Selected address is already the current address');
            setInitialModal(false)
            return;
        }

        //  Fetch nearest branch
        let formdata = new FormData();
        formdata.append("lat", selected.location.latitude.toString());
        formdata.append("lon", selected.location.longitude.toString());

        const branchResponse = await getNearestBranch(formdata);

        if (branchResponse.error) {
            console.error('Error getting nearest branch:', branchResponse.error_msg);
            toast.error(branchResponse.error_msg);
            return
        } else {
            const nearestBranch = branchResponse?.records

            dispatch(setNearestBranch(nearestBranch));
            dispatch(setCurrentAddress({
                id: selected.id,
                user_phone: selected.user_phone || null,
                location: selected.location,
                address: selected.address,
                title: selected.title,
                gpsAddress: selected.gpsAddress,
                city: selected.city,
            }));

            toast.success('Address selected successfully!');
            setInitialModal(false)
        }
    }

    
    const closeModal = () => setInitialModal(false)

    return (
        <>
            <Toaster richColors position="top-center" />
            {
        modalType === "login" &&
        <AuthModal closeModal={closeAuthModal}>
          <Login />
        </AuthModal>
      }
        { hideModal ?  <>
          {savedAddresses.length > 0 ? (
            <AddressSelect
                                 savedAddresses={savedAddresses}
                                 currentAddress={currentAddress}
                                 handleAddressSelect={handleAddressSelect}
                                 hideModal={hideModal}
                               />
          ):<Button
            className="text-white font-semibold text-xs text-center cursor-pointer"
            onClick={() => {
              setAddAddressModal(true)
            }}
            variant={"default"}
          >
            Add a New Address
          </Button> }
        </> :
        <div className="w-full flex flex-col items-center">
          {(initialModal || !currentAddress) && (
            <Modal closeModal={closeModal} hideCloseButton={!!settingsLoading} >
              <>
                {settingsLoading ? (
                  <div className={" w-36 h-36 flex items-center justify-center"}><LoaderSpinner size={"lg"} /></div>
                ) : error ? (
                  <div>Error: {error}</div>
                ) : (
                  <div className="w-full mx-8 flex flex-col items-center gap-4 text-center ">
                    <Lottie
                      animationData={animationData}
                      style={{ width: '100%', maxWidth: '180px', height: 'auto' }}
                      className="-my-8 -mt-12"

                    />
                    <p className="text-xl font-bold text-foreground">Choose Your Address for Delivery</p>
                    {savedAddresses.length > 0 && (
                      <AddressSelect
                                           savedAddresses={savedAddresses}
                                           currentAddress={currentAddress}
                                           handleAddressSelect={handleAddressSelect}
                                         />
                    )}

<div className="w-full flex flex-col items-center gap-2">
                    <Button
                      className="w-full md:w-36 text-white font-semibold text-sm text-center cursor-pointer"
                      onClick={() => {
                        setAddAddressModal(true)
                      }}
                      variant={"default"}
                      size={"sm"} 
                      >
                      Add a New Address
                    </Button>
                    <p className="text-muted-foreground text-sm">
                      Already have an account?
                    <Button onClick={()=> {
                      closeModal()
                      openLoginModal()
                      
                    }} variant={"link"} size={"sm"} >Login</Button>
                    </p>
                      </div>
                  </div> 
                )}
              </>
            </Modal>
          )}
        </div>
       }
        </>)
}

export default InitialModal;

interface SelectProps {
  savedAddresses: any;
  currentAddress: any;
  handleAddressSelect: any;
  hideModal?: boolean;
}
const AddressSelect = ({ savedAddresses, currentAddress, handleAddressSelect, hideModal }: SelectProps) => (
  <div className="w-full relative">
    <select
      name="address"
      id="address"
      className="appearance-none w-full py-2 px-4 pr-8 border border-gray-300 rounded-md bg-white focus:outline-none focus:ring-2 focus:ring-primary"
      value={hideModal ? currentAddress?.address : ""}
      onChange={(e) => {
        const selected = savedAddresses.find((address: any) => address.address === e.target.value);
        handleAddressSelect(selected);
      }}
    >
      {!hideModal &&
      <option value=""  >
        Select an address
        </option>
    }
      {savedAddresses?.map((address: any, index: number) => (
        <option key={index}  value={address.address} className="truncate">
          {address.title.slice(0, 50)}
          {address.title.length > 50 ? "..." : ""}
        </option>
      ))}
    </select>
    <ChevronDown className="pointer-events-none absolute inset-y-0 right-0 flex items-center justify-center h-full px-2" size={32} />
  </div>
);
